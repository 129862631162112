<template>
  <div>
    <hs-loading v-if="isLoading" />
    <div v-else>
      <div class="pb-3 d-flex flex-column pt-2 pt-md-3">
        <div class="hs-popover__content pl-2">
          <div class="d-flex align-items-center mb-2">
            <hs-icon icon="filter" />
            <h4 class="ml-2 font-weight-bold">{{ funnel.name }}</h4>
          </div>
          <div class="funnel-grey-text d-flex flex-column my-4 pl-4">
            <p class="font-size-xs m-0">
              {{ $t('sparkfunnels.funnels.list.funnil-links.created_at') }} {{ funnelsCreatedAt }}
            </p>
            <p class="font-size-xs m-0">
              {{ $t('sparkfunnels.funnels.list.funnil-links.stages') }} {{ loadedFunnel.stages.length }}
            </p>
          </div>
          <div class="d-flex pl-4 flex-column">
            <h5 class="mb-3">{{ $t('sparkfunnels.funnels.list.funnil-links.subtitle') }}</h5>
            <div class="d-flex align-middle">
              <a class="popover-link mb-2" id="funnel-url" ref="funnel-url" :href="stagePageUrl" target="_blank">{{
                stagePageUrl
              }}</a>
              <div class="d-flex ml-3">
                <hs-button
                  id="copy-funnel-url"
                  variant="link"
                  class="text-purple align-self-center p-1"
                  @click="copyToClipboard('funnel-url')"
                >
                  <hs-icon icon="copy" />
                </hs-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { hsLoading } from '@/components';
import dayjs from 'dayjs';
import Clipboard from '@/shared/mixins/Clipboard';
import toastHelper from '@/shared/helpers/toast';

export default {
  name: 'FunnelLinks',
  components: { hsLoading },
  mixins: [Clipboard],
  props: {
    funnel: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
    ...mapGetters('funnel', ['pageUrl']),
    stagePageUrl() {
      return this.pageUrl(this.loadedFunnel.stages[0]);
    },
    funnelsCreatedAt() {
      return this.funnel.created_at ? dayjs(this.funnel.created_at).format('DD/MM/YYYY') : '';
    },
  },
  methods: {
    ...mapActions('funnel', ['loadFunnel']),
    async loadSelectedFunnel() {
      try {
        this.isLoading = true;
        await this.loadFunnel(this.funnel.id);
      } catch {
        toastHelper.dangerMessage('Falha ao carregar informações do funnil.');
      } finally {
        this.isLoading = false;
      }
    },
    copyToClipboard() {
      const result = this.clipboardCopy('funnel-link', this.stagePageUrl);
      if (result) {
        toastHelper.successMessage('Link copiado.');
      } else {
        toastHelper.dangerMessage('Falha ao copiar o link');
      }
    },
  },
  data: () => ({
    isLoading: true,
  }),
  mounted() {
    this.loadSelectedFunnel();
  },
};
</script>
<style lang="scss" scoped>
.funnel-grey-text {
  color: var(--color-gray-800);
}
.popover-link {
  word-break: break-word;
}
.hs-popover {
  border: 0;
  background-color: white;
  min-width: 400px;
  a {
    font-size: 14px;
    color: $purple-dark;
    text-decoration: underline;
    cursor: pointer;
  }
  /deep/ .popover-body {
    border: 1px solid #d3d3d3;
    box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }
  &__title-link-offer {
    position: absolute;
    top: 18px;
    left: 10px;
  }
  &__title-link {
    position: absolute;
    top: 18px;
    left: 10px;
  }
  &__link {
    position: absolute;
    bottom: 21px;
    color: #0fa0a9;
    right: 10px;
  }
  &__link-offer {
    color: #0fa0a9;
    &--active {
      color: $green-dark;
    }
  }
  &__content {
    padding-left: 35px;
    h4 {
      font-size: 16px;
    }
  }
  .close-icon {
    display: none;
  }
  .links {
    justify-content: space-between;
    align-items: center;
    a {
      width: 80%;
    }
  }
}
.sales-link {
  cursor: pointer;
  text-decoration: underline !important;
}
@media screen and (max-width: 576px) {
  .hs-popover {
    min-width: 100vw;
    height: 100vh;
    position: fixed !important;
    border-radius: 0;
    background: rgba(0, 0, 0, 0.5);
    transform: translateY(0) !important;
    /deep/ .popover-body {
      min-width: 100vw;
      max-width: 100vw;
      position: absolute;
      bottom: 0;
      border-radius: 0;
      background-color: white;
    }
    /deep/ .arrow {
      display: none;
    }
    .close-icon {
      display: none;
    }
    &__title-link-offer {
      top: 52px;
    }
  }
}
</style>
