







import ObjectiveSelection from './components/ObjectiveSelection.vue';
import TemplateSelection from './components/TemplateSelection/index.vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ObjectiveSelection,
    TemplateSelection,
  },
  created() {
    this.$store.dispatch('funnel/funnelObjectives');
  },
  mounted() {
    this.$store.dispatch('funnel/cleanObjectivesSelected');
  },
})
export default class FunnelsCreate extends Vue {
  changeFunnelCreation(newFunnelCreation) {
    this.$store.dispatch('funnel/objectivesSelected', newFunnelCreation);
  }
}
