









































import { Vue, Component, Prop } from 'vue-property-decorator';
import TemplateContent from '@/sparkfunnels/views/Funnels/Create/components/TemplateCardObjectives/TemplateContent.vue';
import ChooseFunnelNameModal from '@/sparkfunnels/views/Funnels/Create/components/ChooseFunnelNameModal/index.vue';
const ImageDefault = require('@/assets/images/SparkFunnels/Funnels/Create/default-funnel-illustration.svg');
import HsButton from '@/components/hsButton.vue';
import { imagePathResolve } from '@/sparkfunnels/helpers/imagePathResolve';
import { namespace } from 'vuex-class';

const FunnelModule = namespace('funnel');

const COLOR_LEVEL = {
  INICIANTE: '#2050B5',
  INTERMEDIARIO: '#EDA81F',
  AVANCADO: '#F94343',
};

@Component({
  components: {
    TemplateContent,
    ChooseFunnelNameModal,
    HsButton,
  },
  computed: {
    prefix() {
      return 'sparkfunnels.funnels.create.steps.template_selection.card';
    },
  },
})
export default class TemplateCardObjectives extends Vue {
  @Prop({ type: Object, default: [] }) template: any;
  @FunnelModule.Action strategyTemplates;

  get showCard(): boolean {
    return this.objectivesFilter.includes(this.template.id);
  }

  borderLevel(level) {
    return `border-top: 12px solid ${COLOR_LEVEL[this.removeSpecialCharacter(level)]}`;
  }

  backgroundLevel(level) {
    return `background: ${COLOR_LEVEL[this.removeSpecialCharacter(level)]}; color: ${this.textLevel(level)}`;
  }

  textLevel(level) {
    return this.removeSpecialCharacter(level) === 'INTERMEDIARIO' ? '#000000' : '#ffffff';
  }

  removeSpecialCharacter(str) {
    return str
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toUpperCase();
  }

  async namedTemplate(template) {
    this.$bvModal.show(`choose-funnel-modal-${template.id}${template.idObjective}`);
  }

  selectTemplate(templateId, funnelName) {
    this.$emit('selected', { templateId, funnelName });
  }

  imagePath(image) {
    return imagePathResolve(image, ImageDefault);
  }

  get objectivesFilter() {
    return this.$store.getters['funnel/objectivesFilter'];
  }

  get hasStrategyTags() {
    return this.template['funnel_strategy_tags'].length > 0;
  }

  created() {
    this.strategyTemplates(this.template.id);
  }
}
