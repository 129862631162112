





























import { Component, Vue } from 'vue-property-decorator';
import ContainerImages from '@/sparkfunnels/views/Funnels/Create/components/TemplateCardObjectives/ContainerImages.vue';

@Component({
  props: {
    funnel: {
      type: Object,
      default: {},
    },
  },
  components: { ContainerImages },
  computed: {
    prefix() {
      return 'sparkfunnels.funnels.create.steps.template_selection.card.contents';
    },
  },
})
export default class TemplateContent extends Vue {
  get iconImage(): String {
    return '';
  }
}
