<template>
  <carousel
    class="objectives-check-box__carousel-wrapper"
    :perPageCustom="perPageCustom"
    :paginationEnabled="false"
    :navigationEnabled="false"
    :centerMode="false"
    :loop="true"
  >
    <slide
      v-for="objective in objectivesInOrder"
      :key="objective.id"
      class="check-objectives"
      @slideclick="onToggleOption(objective.id)"
      style="flex-basis: 180px"
    >
      <button :class="{ 'check-active': selected.has(objective.id) }">
        <img :src="imagePath(objective.banner)" size="30" :alt="objective.title" />
      </button>
      <p :class="{ 'text-check-active': selected.has(objective.id) }">
        {{ objective.title }}
      </p>
      <CheckAnimate v-if="selected.has(objective.id)" class="check-animate" />
    </slide>
  </carousel>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import CheckAnimate from '@/sparkfunnels/components/CheckAnimate';
import { imagePathResolve } from '@/sparkfunnels/helpers/imagePathResolve';
import _ from 'lodash';
const EmptyImage = require('@/assets/images/funnel/funnels_empty.svg');

export default {
  name: 'ObjectivesCheckBox',
  components: { Carousel, Slide, CheckAnimate },
  props: {
    objectives: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selected: new Set(),
      perPageCustom: [[480, 3], [768, 4], [960, 5], [1024, 6]],
    };
  },
  watch: {
    selected(newValue) {
      this.$emit('input', newValue);
    },
  },
  computed: {
    objectivesInOrder() {
      return _.orderBy(this.objectives, 'id');
    },
  },
  methods: {
    onToggleOption(id) {
      this.controllingSelectedIds(id);
    },
    controllingSelectedIds(id) {
      this.selected[this.selected.has(id) ? 'delete' : 'add'](id);
      this.selected = new Set(this.selected);
    },
    imagePath(image) {
      return imagePathResolve(image, EmptyImage);
    },
  },
};
</script>

<style lang="scss">
.VueCarousel-wrapper {
  .VueCarousel-inner {
    min-height: 138px !important;
  }
}

.objectives-check-box__carousel-wrapper {
  width: 100%;
  background: #e3e3e3;

  > div {
    > div {
      justify-content: space-between;
    }
  }
}

.check-objectives {
  width: 166px;
  height: 100px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  align-items: center;
  justify-content: center;

  > button {
    width: 168px;
    height: 100px;

    align-items: center;
    justify-content: center;
    display: flex;

    background: $white;
    border-radius: 5px;

    &:hover {
      border: 2px solid #cbcbcb;
    }
  }
  > .check-animate {
    width: 19px;
    height: 19px;
    display: block;
  }
  .check-active {
    border: 2px solid #7427f1;
  }
  > p {
    max-width: 168px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin-top: 16px;

    font-size: 14px;
    font-weight: normal;
    text-align: center;
    word-break: break-word;
  }
  .text-check-active {
    font-weight: bold;
  }
}
</style>
