<template>
  <div>
    <div class="d-none d-md-block">
      <div class="desktop-list">
        <hs-table
          ref="selectableTable"
          :responsive="true"
          :hover="true"
          :fields="fields"
          :items="items"
          :select-mode="'single'"
          v-bind="$attrs"
          v-on="$listeners"
          :busy="loading === 'removing' || loading === 'paging'"
        >
          <template slot="name" slot-scope="row">
            <section class="d-flex flex-column">
              <span class="mb-2">{{ row.item.name || '&nbsp;' }}</span>

              <div @click.prevent="openPopover(`popover-target-${row.item.id}`)">
                <hs-icon class="mr-1 pointer popover-trigger popover-icon tlw-text-sm" icon="link" />
                <a href="#" class="popover-trigger popover-link" :id="`popover-target-${row.item.id}`">
                  {{ $t('sparkfunnels.funnels.list.link-popover') }}
                  <hs-icon class="ml-2 pointer popover-trigger" size="14" icon="chevron-down" />
                </a>
              </div>

              <b-popover
                class=""
                :ref="`popover-target-${row.item.id}`"
                :target="`popover-target-${row.item.id}`"
                custom-class="hs-popover"
                placement="bottom"
              >
                <FunnelLinks v-if="openedPopover == `popover-target-${row.item.id}`" :funnel="row.item" />
              </b-popover>
            </section>
          </template>
          <template slot="leads_count" slot-scope="row" class="d-none">
            <section>
              <span v-if="row.item.leads_count !== undefined">{{
                $t('sparkfunnels.funnels.list.leads_count', { count: row.item.leads_count })
              }}</span>
            </section>
          </template>
          <template slot="status" slot-scope="row">
            <section>
              <div class="tlw-flex-grow tlw-mt-2 program-item__card-enable tlw-flex tlw-gap-x-3 tlw-flex-row">
                <MSwitch
                  :id="row.item.id ? row.item.id.toString() : ''"
                  @change="toggle(row)"
                  v-model="row.item.isActive"
                />

                {{ row.item.isActive ? 'Ativo' : 'Inativo' }}
              </div>
            </section>
          </template>
          <template slot="actions" slot-scope="row">
            <section
              class="text-right d-flex align-items-center justify-content-end"
              v-if="row.item.leads_count !== undefined"
            >
              <Dropdown
                @edit-clicked="$emit('edit-clicked', { funnel: row.item })"
                @show-leads="$router.push({ name: 'FunnelLeads', params: { funnelId: row.item.id } })"
                @duplicate="$emit('duplicate', { funnelId: row.item.id })"
                @delete="$emit('delete', { funnel: row.item })"
              />
            </section>
          </template>
        </hs-table>
      </div>
    </div>
    <div class="d-md-none mobile-list mt-1">
      <div v-for="item in items" :key="item.id" class="funnels-list-card bg-white my-4 p-3 d-flex flex-column">
        <span class="funnel-name">{{ item.name || '&nbsp;' }}</span>
        <div class="d-flex align-items-center mb-2">
          <span v-if="item.leads_count !== undefined">{{
            $t('sparkfunnels.funnels.list.leads_count', { count: item.leads_count })
          }}</span>
          <div class="flex-grow-1 tlw-gap-x-3 mr-3 program-item__card-enable justify-content-end tlw-flex">
            <MSwitch :id="item.id ? item.id.toString() : ''" @change="toggleMobile(item)" v-model="item.isActive" />

            {{ item.isActive ? 'Ativo' : 'Inativo' }}
          </div>
          <div class="mobile-toggle text-right d-flex" v-if="item.leads_count !== undefined">
            <Dropdown
              @edit-clicked="$emit('edit-clicked', { funnel: item })"
              @show-leads="$router.push({ name: 'FunnelLeads', params: { funnelId: item.id } })"
              @duplicate="$emit('duplicate', { funnelId: item.id })"
              @delete="$emit('delete', { funnel: item })"
            />
          </div>
        </div>
        <div class="mt-1">
          <div @click.prevent="openPopover(`popover-mobile-target-${item.id}`)">
            <hs-icon class="mr-1 pointer popover-trigger popover-icon tlw-text-sm" icon="link" />
            <a href="#" class="popover-trigger popover-link" :id="`popover-mobile-target-${item.id}`">
              {{ $t('sparkmembers.v2-product-list.card.link-popover') }}
              <hs-icon class="ml-2 pointer popover-trigger" size="14" icon="chevron-down" />
            </a>
          </div>

          <b-popover
            class=""
            :ref="`popover-mobile-target-${item.id}`"
            :target="`popover-mobile-target-${item.id}`"
            custom-class="hs-popover"
            placement="bottom"
          >
            <FunnelLinks v-if="openedPopover == `popover-mobile-target-${item.id}`" :funnel="item" />
          </b-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dropdown from './Dropdown';
import MSwitch from '@/shared/components/MSwitch.vue';
import FunnelLinks from './FunnelLinks';
import PopoverControllerMixin from '@/sparkmembers/mixins/PopoverControllerMixin';
import { activeLabel } from '@/libs/labels';
export default {
  name: 'custom-table',
  mixins: [PopoverControllerMixin],
  components: {
    Dropdown,
    MSwitch,
    FunnelLinks,
  },
  props: {
    items: {
      default: () => [{}, {}, {}, {}],
    },
    loading: {
      type: String,
      default: '',
    },
  },
  watch: {
    onChange(value) {
      this.changePage(value);
    },
  },
  computed: {
    fields() {
      return [
        {
          key: 'name',
          label: this.$t('sparkfunnels.funnels.list.details.table.name'),
          sortable: true,
        },
        {
          key: 'leads_count',
          label: this.$t('sparkfunnels.funnels.list.details.table.leads_count'),
        },
        {
          key: 'status',
          label: this.$t('sparkfunnels.funnels.list.details.table.status.label'),
        },
        {
          key: 'actions',
          label: '',
        },
      ];
    },
  },
  created() {
    this.installEventListener();
  },
  destroyed() {
    this.removeEventListener();
  },
  methods: {
    clearSelected() {
      this.$refs.selectableTable.clearSelected();
    },
    edit(row) {
      this.$emit('edit-clicked', row);
    },
    onClose() {
      this.refs.popover.$emit('close');
    },
    toggle(row) {
      const currentStatus = activeLabel(!row.item.isActive);
      const newStatus = activeLabel(row.item.isActive);
      this.$emit('status-toggled', { id: row.item.id, newStatus, currentStatus });
    },
    toggleMobile(item) {
      const currentStatus = activeLabel(!item.isActive);
      const newStatus = activeLabel(item.isActive);
      this.$emit('status-toggled', { id: item.id, newStatus, currentStatus });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-button:focus {
  outline-color: transparent;
}
.popover-link {
  font-size: 12px;
}
.funnel-name {
  font-weight: 600;
  margin-bottom: 12px;
}
.popover-link {
  color: $purple-dark;
  text-decoration: underline;
}
.popover-icon {
  color: $purple-dark;
}
/deep/.dropdown-toggle {
  padding-top: 0;
  padding-bottom: 0;
}
/deep/.custom-control {
  display: flex;
  align-items: center;
}
/deep/.table-responsive {
  min-height: 210px;
}
</style>
