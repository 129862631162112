











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ContainerImages extends Vue {
  @Prop({ default: '' }) imageIcon: string;
  @Prop({ default: '' }) altImage: string;
  @Prop({ default: '' }) textDescription: string;
}
