

























































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { hsForm } from '@/components';
import { StrategyTemplatesType } from '@/types/funnel';
import { imagePathResolve } from '@/sparkfunnels/helpers/imagePathResolve';
import { TemplateThemeType } from '@/types/funnel';
import { namespace } from 'vuex-class';

const FunnelModule = namespace('funnel');
const ImageDefault = require('@/assets/images/SparkFunnels/Funnels/Create/default-funnel-illustration.svg');

@Component({
  components: {
    hsForm,
  },
})
export default class ChooseFunnelNameModal extends Vue {
  @Prop({ type: Object, default: [] }) templateTheme: TemplateThemeType;
  @FunnelModule.Getter strategyTemplates: StrategyTemplatesType;
  funnelName: string = '';
  templateStrategy: object = [];
  showSelectField: boolean = true;

  get disableButton() {
    return this.strategyTemplates.length < 1 || this.funnelName === '';
  }

  get prefix() {
    return 'sparkfunnels.funnels.create.steps.template_selection.modal';
  }

  templateStrategyValues() {
    this.templateStrategy = this.strategyTemplates[0];
  }

  async next() {
    let templateId;
    this.strategyTemplates.forEach(strategy => {
      if (strategy.strategyId === this.templateTheme.id) templateId = strategy.id;
    });

    this.$emit('next', templateId, this.funnelName);
  }

  closeModal() {
    this.$bvModal.hide(`choose-funnel-modal-${this.templateTheme.id}${this.templateTheme.idObjective}`);
  }

  showFunnelTheme(templateStrategy) {
    return (templateStrategy || []).length > 1;
  }

  imagePath(image) {
    return imagePathResolve(image, ImageDefault);
  }

  mounted() {
    this.funnelName = this.templateTheme.name;
    this.showSelectField = this.showFunnelTheme(this.strategyTemplates);
    this.templateStrategyValues();
  }

  @Watch('strategyTemplates')
  strategyTemplatesChanged(newVal: object) {
    this.templateStrategy = newVal[0];
  }
}
