






















import { Component, Vue } from 'vue-property-decorator';
import { funnelApi } from '@/services'
import HsLoading from '@/components/Loading.vue';
import ToastHelper from '@/shared/helpers/toast';
import TrackingHelper from '@/shared/helpers/tracking';
import TemplateCardObjectives from '@/sparkfunnels/views/Funnels/Create/components/TemplateCardObjectives/index.vue';
import { namespace } from 'vuex-class';

const FunnelModule = namespace('school');

@Component({
  components: {
    HsLoading,
    TemplateCardObjectives,
  },
})

export default class TemplateSelection extends Vue {
  @FunnelModule.State selectedSchool!: any;
  private isLoading: boolean = false;
  private newFunnelId: string;
  private schoolId: string;

  selectTemplate({ templateId, funnelName }) {
    this.isLoading = true;
    let schoolId = this.schoolId
    funnelApi
        .createFromTemplate({ funnelTemplateId: templateId, funnelName, schoolId })
        .then(({ data }) => {
          this.newFunnelId = data.id;
          let route = this.$route?.query?.location || 'funnel';
          TrackingHelper.trackFunnelCreated(
              data.template_name,
              schoolId,
              route.toString()
          );
          this.$emit('change', { funnel: data });
        })
        .then(() => this.$emit('proceed'))
        .then(() => this.$store.dispatch('funnel/updateStatus', { funnelId: this.newFunnelId, newStatus: 'active' }))
        .then(() => this.$router.push({ name: 'FunnelEdit', params: { funnelId: this.newFunnelId } }))
        .catch(() => ToastHelper.dangerMessage(this.$t(`${this.prefix}.selection_error`)))
        .finally(() => (this.isLoading = false));
  }

  get prefix() {
    return 'sparkfunnels.funnels.create.steps.template_selection'
  }

  get strategies() {
    return this.$store.getters["funnel/strategies"]
  }

  get objectivesFilter() {
    return this.$store.getters["funnel/objectivesFilter"]
  }

  created() {
    this.schoolId = this.selectedSchool.id;
  }
}
