<template>
  <section class="d-flex flex-wrap justify-content-between h-100 funnel-details-container bg-white">
    <hsLoading v-if="isLoading" />
    <template v-else>
      <div class="w-100">
        <Information v-on="$listeners" />
        <b-row>
          <b-col>
            <hs-card-analytics
              id="revenues-card-analytics"
              :label="$t('sparkfunnels.funnels.list.details.data_type.revenues')"
              :value="incomeCount"
              icon="badge-dollar"
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <hs-card-analytics
              id="leads-count-card-analytics"
              :label="$t('sparkfunnels.funnels.list.details.data_type.leads_count')"
              :value="leadsCount"
              icon="user"
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <hs-card-analytics
              id="sales-card-analytics"
              :label="$t('sparkfunnels.funnels.list.details.data_type.sales')"
              :value="salesCount"
              icon="chart-line"
            />
          </b-col>
        </b-row>
        <b-row class="pt-3">
          <b-col>
            <hs-card-analytics
              id="conversion-card-analytics"
              :label="$t('sparkfunnels.funnels.list.details.data_type.conversion')"
              :value="conversionRate"
              icon="funnel-dollar"
            />
          </b-col>
        </b-row>
      </div>
      <b-row no-gutters class="w-100" align-v="end">
        <b-col class="pt-3 w-100 align-items d-flex justify-content-center" cols="12" md="auto">
          <hs-button
            id="see-analytics-button"
            icon="chart-line"
            variant="primary"
            :to="{ name: 'FunnelAnalytics', params: { funnelId: selectedFunnel.id } }"
          >
            {{ $t('sparkfunnels.funnels.list.details.see_analytics') }}
          </hs-button>

          <hs-button
            id="see-leeds-button"
            icon="user"
            variant="primary"
            :to="{ name: 'FunnelLeads', params: { funnelId: selectedFunnel.id } }"
          >
            {{ $t('sparkfunnels.funnels.list.details.see_leads') }}
          </hs-button>
        </b-col>
      </b-row>
    </template>
  </section>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { hsLoading } from '@/components';
import Information from './Information.vue';
import ToastHelper from '@/shared/helpers/toast';

export default {
  components: {
    hsLoading,
    Information,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState('funnel', {
      selectedFunnel: state => state.selectedFunnel,
      loadedFunnel: state => state.loadedFunnel,
    }),
    conversionRate() {
      if (!this.loadedFunnel.conversion_rate) return '-';
      return `${Math.round(this.loadedFunnel.conversion_rate * 100) / 100}%`;
    },
    incomeCount() {
      if (this.loadedFunnel.income_count === 0) return '-';
      return this.$options.filters.currency(this.loadedFunnel.income_count / 100, {
        symbol: this.$t('money.symbol'),
        thousandsSeparator: this.$t('money.thousands_separator'),
        fractionSeparator: this.$t('money.fraction_separator'),
      });
    },
    leadsCount() {
      return this.loadedFunnel.leads_count > 0 ? this.loadedFunnel.leads_count.toString() : '-';
    },
    salesCount() {
      return this.loadedFunnel.sales_count > 0 ? this.loadedFunnel.sales_count.toString() : '-';
    },
  },
  methods: {
    ...mapActions('funnel', ['getFunnel']),
    ...mapMutations('funnel', ['setSelectedFunnel', 'setLoadedFunnel']),
    fetchFunnel() {
      this.isLoading = true;
      this.getFunnel(this.selectedFunnel.id).then(
        ({ data }) => {
          this.isLoading = false;
          this.setLoadedFunnel(data);
        },
        () => {
          this.isLoading = false;
          ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.details.toast.get_funnel_error'));
        }
      );
    },
  },
  watch: {
    selectedFunnel() {
      this.fetchFunnel();
    },
  },
  created() {
    this.fetchFunnel();
  },
  beforeDestroy() {
    this.setSelectedFunnel(null);
  },
};
</script>

<style lang="scss" scoped>
.funnel-details-container {
  width: 266px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(38, 38, 38, 0.05);
  padding: 31px 26px 22px;
}

.align-items {
  @media only screen and (max-width: 1600px) {
    align-items: flex-start;
    flex-direction: column;
    .btn {
      padding-left: 0;
    }
  }
}
</style>
