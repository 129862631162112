<template>
  <div>
    <b-row class="d-flex justify-content-end mr-0 height-adjust">
      <button type="button" aria-label="Close" class="close" @click="$emit('close')">×</button>
    </b-row>
    <b-row>
      <b-col class="name">
        {{ loadedFunnel.name }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="created-at">
        {{ `${$t('sparkfunnels.funnels.list.details.created_at')}:` }}
        {{ format(loadedFunnel.created_at, $t('date.format')) }}
      </b-col>
    </b-row>
    <b-row>
      <b-col class="stages">
        {{ `${$t('sparkfunnels.funnels.list.details.stages')}: ${loadedFunnel.stages.length}` }}
      </b-col>
    </b-row>
    <b-row data-testid="funnel-page-url" class="mb-4" v-if="hasSqueezePage || hasFunnelPage">
      <b-col>
        <a class="page-url-link text-break font-weight-bold text-primary" name="page-url" :href="url" target="_blank">
          {{ url }}
        </a>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { format } from '@/shared/helpers/dates';

export default {
  methods: {
    format,
  },
  computed: {
    ...mapGetters('funnel', ['getFirstSqueezePage', 'hasSqueezePage', 'hasFunnelPage', 'pageUrl']),
    ...mapGetters('school', ['getDomainWithHttp']),
    ...mapState('funnel', {
      loadedFunnel: state => state.loadedFunnel,
    }),
    url() {
      return this.pageUrl(this.getFirstSqueezePage);
    },
  },
};
</script>

<style lang="scss" scoped>
.close {
  margin-top: -16px;
}
.height-adjust {
  height: 0;
}
.name {
  color: $grey;
  font-weight: bold;
  letter-spacing: -0.33px;
  line-height: 27px;
  font-size: 20px;
}
.created-at,
.stages {
  color: $grey-30;
  font-size: 10px;
  letter-spacing: -0.17px;
  line-height: 20px;
}
.stages {
  padding-bottom: 20px;
}
.page-url-link {
  font-size: 16px;
  text-decoration: underline;
}
</style>
