
















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ObjectivesCheckBox from '../components/ObjectivesCheckBox/index.vue';

const FunnelModule = namespace('funnel');

@Component({
  components: { ObjectivesCheckBox },
})
export default class ObjectiveSelection extends Vue {
  @FunnelModule.Getter funnelObjectives!: any;
  @FunnelModule.Getter objectivesSelected!: any;

  get selection() {
    return this.objectivesSelected;
  }

  set selection(objectives) {
    this.$emit('change', { objectives });
  }
}
