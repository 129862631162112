<template>
  <div class="check">
    <hs-icon icon="check" color="green" size="12" />
  </div>
</template>

<script>
export default {
  name: 'CheckAnimate',
};
</script>

<style lang="scss" scoped>
.check {
  position: absolute;
  width: 19px;
  height: 19px;
  margin: 90px 0 0 0;
  border-radius: 50%;

  background: #7427f1;

  text-align: center;
  line-height: 19px;
  > i {
    width: 19px;
    height: 19px;
    color: $white;
  }
}
</style>
