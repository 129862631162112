<template>
  <section>
    <PageHeader
      :title="$t('sparkfunnels.funnels.header.title')"
      :subTitle="$t('sparkfunnels.funnels.header.sub-title')"
    >
      <template #actions>
        <div class="funnels-list-actions">
          <MXButton id="create-campaign-btn" variant="secondary" @click="createCampaign">
            Campanhas
          </MXButton>
          <MXButton
            id="create-funnel-btn"
            variant="primary"
            :disabled="isLoading || (limitFunnel != 0 && theAmountFunnels >= limitFunnel)"
            @click="createFunnel"
          >
            {{ $t('sparkfunnels.funnels.actions.create') }}
          </MXButton>
        </div>
      </template>
    </PageHeader>
    <hsLoading class="my-5" v-if="isLoading" />
    <section v-else>
      <section id="funnel-list-section" class="py-4 px-5 w-100 d-flex fix-overflow">
        <hsSidebar :opened="!!selectedFunnel" class="flex-grow-1 w-100" :class="{ 'overflow-auto': selectedFunnel }">
          <template v-slot:content>
            <div class="h-100 w-100">
              <template>
                <hsNoTableData
                  v-if="!funnels.length"
                  :title="$t('sparkfunnels.funnels.list.no_funnels.title')"
                  :description="$t('sparkfunnels.funnels.list.no_funnels.description')"
                  :image="require('@/assets/images/funnel/funnels_empty.svg')"
                  :buttonTitle="$t('sparkfunnels.funnels.list.no_funnels.create_button')"
                  @action-clicked="createFunnel"
                >
                  <hsPageTitle>
                    <template slot="actions" v-if="funnels.length">
                      <MXButton @click="createFunnel" size="small">
                        {{ $t('sparkfunnels.funnels.actions.create') }}
                      </MXButton>
                    </template>
                  </hsPageTitle>
                  <CustomTable />
                </hsNoTableData>
                <div v-else>
                  <div class="tlw-flex flex-lg-nowrap flex-md-nowrap flex-wrap">
                    <LeadCard :linkForLeads="true" title="Meus leads" :theAmount="theAmount" :theLimite="theLimite" />
                    <CardPlan
                      :title="$t('sparkfunnels.funnels.card-plan.title')"
                      v-if="limitFunnel"
                      :subTitle="
                        $t('sparkfunnels.funnels.card-plan.sub-title', {
                          limit: limitFunnel,
                        })
                      "
                    />
                  </div>
                  <div id="funnels-table" class="h-100 d-flex flex-column justify-content-between">
                    <div>
                      <CustomTable
                        ref="customTable"
                        :items="funnels"
                        @edit-clicked="edit"
                        @status-toggled="toggleStatus"
                        @selected-items="selectFunnel"
                        @duplicate="duplicateFunnel"
                        @delete="deleteFunnel"
                      />
                    </div>
                    <hs-pagination
                      v-if="totalRows > perPage"
                      v-model="currentPage"
                      :total-rows="totalRows"
                      :go-page-text="$t('sparkfunnels.funnels.list.go_page_text')"
                      :per-page="perPage"
                      align="center"
                    ></hs-pagination>
                  </div>
                </div>
              </template>
            </div>
          </template>
          <template v-slot:sidebar>
            <div class="pl-3">
              <FunnelDetails @close="clearSelected" />
            </div>
          </template>
        </hsSidebar>
      </section>
    </section>
  </section>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import ToastHelper from '@/shared/helpers/toast';
import Confirm from '@/shared/mixins/Confirm';
import FunnelDetails from './components/FunnelDetails';
import { hsSidebar, hsLoading, hsNoTableData } from '@/components';
import { analyticsService, funnelApi } from '@/services';
import CustomTable from './components/Table';
import hsPageTitle from '@/components/_structures/PageTitle';
import PageHeader from '@/components/_structures/PageHeader.vue';
import MXButton from '@/shared/components/MXButton.vue';
import LeadCard from '../../Leads/LeadCard/LeadCard';
import CardPlan from './components/CardPlan.vue';
import heimdallService from '@/services/heimdall/newPlans';

import TrackingHelper from '@/shared/helpers/tracking';

export default {
  name: 'funnels-list',
  mixins: [Confirm],
  components: {
    hsLoading,
    hsNoTableData,
    hsPageTitle,
    CustomTable,
    hsSidebar,
    FunnelDetails,
    PageHeader,
    LeadCard,
    CardPlan,
    MXButton,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      perPage: 8,
      totalPages: 0,
      totalRows: 0,
      theAmount: 0,
      theAmountFunnels: 0,
      theLimite: 0,
      limitFunnel: 0,
    };
  },
  computed: {
    ...mapState('funnel', {
      funnels: state => state.items,
      selectedFunnel: state => state.selectedFunnel,
    }),
  },
  watch: {
    currentPage(newPage, oldPage) {
      if (newPage != oldPage && newPage <= this.totalPages) {
        this.fetchFunnels();
      }
    },
  },
  methods: {
    ...mapActions('funnel', ['getFunnelsLimit']),
    ...mapActions('funnel', ['getFunnels', 'updateStatus', 'getFunnelsLimit']),
    ...mapMutations('funnel', ['setSelectedFunnel', 'setOpenCreateFunnelModal', 'setFunnelStatus']),
    clearSelected() {
      this.setSelectedFunnel(null);
      if (this.$refs.customTable) this.$refs.customTable.clearSelected();
    },
    edit({ funnel }) {
      this.isLoading = true;
      this.$router.push({ name: 'FunnelEdit', params: { funnelId: funnel.id } });
    },
    async toggleStatus({ id, currentStatus, newStatus }) {
      let confirmed = true;

      if (newStatus === 'unactive') {
        confirmed = await this.showConfirmBox({
          okTitle: this.$t('sparkfunnels.funnels.list.status_modal.ok_button'),
          cancelTitle: this.$t('sparkfunnels.funnels.list.status_modal.cancel_button'),
          contentTitle: this.$t('sparkfunnels.funnels.list.status_modal.content_title'),
          contentDescription: this.$t('sparkfunnels.funnels.list.status_modal.content_description'),
        });
      }

      if (confirmed) {
        this.updateStatus({ funnelId: id, newStatus }).then(
          () =>
            ToastHelper.successMessage(this.$t(`sparkfunnels.funnels.list.toast.update_status_success.${newStatus}`)),
          () => {
            ToastHelper.dangerMessage(this.$t(`sparkfunnels.funnels.list.toast.update_status_error.${newStatus}`));
            this.setFunnelStatus({ funnelId: id, newStatus: currentStatus });
          }
        );
      } else {
        this.setFunnelStatus({ funnelId: id, newStatus: currentStatus });
      }
    },
    fetchFunnels() {
      const params = {
        perPage: this.perPage,
        currentPage: this.currentPage,
      };
      this.getFunnels(params)
        .then(res => {
          this.totalPages = res.total_pages;
          this.perPage = res.per_page;
          this.totalRows = res.total_count;
        })
        .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.toast.request_error')));
    },
    selectFunnel(row) {
      this.setSelectedFunnel(row[0]);
    },
    createFunnel() {
      analyticsService.track({
        event: 'Funnel Selection Screen',
        props: {
          location: 'funnels',
        },
      });

      TrackingHelper.trackFunnelCreationInitiated('funnel_catalog');

      this.$router.push({ name: 'FunnelCreate', query: { location: 'funnel' } });
    },
    async duplicateFunnel({ funnelId }) {
      const confirmed = await this.showConfirmBox({
        icon: 'copy',
        variant: 'primary',
        okTitle: this.$t('sparkfunnels.funnels.list.actions.duplicate.confirmation.ok_title'),
        cancelTitle: this.$t('sparkfunnels.funnels.list.actions.duplicate.confirmation.cancel_title'),
        contentTitle: this.$t('sparkfunnels.funnels.list.actions.duplicate.confirmation.title'),
        contentDescription: this.$t('sparkfunnels.funnels.list.actions.duplicate.confirmation.description'),
      });

      if (confirmed) {
        funnelApi
          .duplicateFunnel({ funnelId })
          .then(() => {
            ToastHelper.successMessage(this.$t('sparkfunnels.funnels.list.actions.duplicate.success'));
            this.fetchFunnels();
          })
          .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.actions.duplicate.error')));
      }
    },
    async deleteFunnel({ funnel }) {
      const confirmed = await this.showConfirmBox({
        icon: 'trash-alt',
        variant: 'cherry',
        okTitle: this.$t('sparkfunnels.funnels.list.actions.delete.confirmation.ok_title'),
        cancelTitle: this.$t('sparkfunnels.funnels.list.actions.delete.confirmation.cancel_title'),
        contentTitle: this.$t('sparkfunnels.funnels.list.actions.delete.confirmation.title'),
        contentDescription: this.$t('sparkfunnels.funnels.list.actions.delete.confirmation.description', {
          name: funnel.name,
        }),
      });

      if (confirmed) {
        funnelApi
          .deleteFunnels([funnel.id])
          .then(() => {
            analyticsService.track({
              event: 'Funnels_deleted',
              props: {
                funnel_id: funnel.id,
                funnel_model_type: funnel.template_name,
                funnel_name: funnel.name,
              },
            });
            this.showLeadCard();
            ToastHelper.successMessage(this.$t('sparkfunnels.funnels.list.actions.delete.success'));
            this.fetchFunnels();
          })
          .catch(() => ToastHelper.dangerMessage(this.$t('sparkfunnels.funnels.list.actions.delete.error')));
      }
    },
    async showLeadCard() {
      this.isLoading = true;
      try {
        const { funnels, leads } = await this.getFunnelsLimit();
        this.theAmount = leads || 0;
        this.theAmountFunnels = funnels || 0;

        const {
          data: { limit_leads, limit_funnel },
        } = await heimdallService.getFeatureLimit();
        this.theLimite = limit_leads || 0;
        this.limitFunnel = limit_funnel || 0;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
      }
    },
    createCampaign() {
      this.$router.push({ name: 'CampaignsList' });
    },
  },
  async created() {
    await this.showLeadCard();
    await this.fetchFunnels();
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/mixins/_page_title.scss';

.page__content {
  @media only screen and (max-width: $screen-sm) {
    background: #e3e3e3;
  }
}

.title {
  @include title();
  width: 125px;
}

.funnels-list-actions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 14px;
  width: 100%;

  @media (min-width: 768px) {
    display: flex;
    gap: 16px;
  }
}

@media screen and (max-width: $screen-sm) {
  #funnel-list-section {
    padding: 20px !important;
    &.fix-overflow {
      overflow-x: auto;
    }
  }
}
</style>
